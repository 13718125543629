<template>
  <!-- 알림 팝업 -->
  <div id="popupContainer" class="popup-wrap" v-if="popupList.length > 0">
    <!-- 알림 팝업 스타일1 -->
    <div class="popup pop-list" ref="pop-list">
      <div class="popup-contents">
        <teamplate v-for="popup in popupList">
          <img class="popup-image" v-if="popup.url" :src="popup.url">
        </teamplate>
        <!-- <swiper class="swiper" :options="swiperOption" oncha>
          <swiper-slide v-for="popup in popupList" >
            <img class="popup-image" v-if="popup.url" :src="popup.url">
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination" v-if="popupList.length > 1"></div>
          <div class="swiper-button-prev" slot="button-prev" v-if="popupList.length > 1"></div>
          <div class="swiper-button-next" slot="button-next" v-if="popupList.length > 1"></div>
        </swiper> -->
      </div>
      <div class="popup-check">
        <button @click="closePop('pop-list', false)" class="btn">닫기</button>
      </div>
    </div>
    <!-- // 알림 팝업 스타일1 -->
  </div>
  <!-- // 알림 팝업 -->
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'
  export default {
    name: 'popupList',
    title: 'popupList',
    components: {
      Swiper,
      SwiperSlide
    },
    props: {
      popupList: {
        type: Array,
        default: () => {
          return [];
        }
      },
      position: {
        type: Number,
        default: () => {
          return 0
        }
      }
    },
    data() {
      return {
        swiperOption: {
          slidesPerView: 1,
          spaceBetween: 0,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          breakpoints: {
            500: {
              width: 500,
              spaceBetween: 0
            }
          }
        }
      }
    },
    methods: {
      closePop(tag, todayNoshow) {
        console.log(todayNoshow)
        if (todayNoshow) {
          let a = new Date();
          a.setHours(23, 59, 59)
          this.$cookies.set('pop-list', true, a, '/');
        } else {
          this.$cookies.remove('pop-list');
        }
        const $target = this.$refs['pop-list'];
        $target.classList.add('hide');
        this.$emit('close');
      },
    }
  }
</script>

<style>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  height: initial;
}

.popup.pop-list {
  padding: 0 !important;
}

.popup-check .btn {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 10px;
  font-weight: bold;
}
.popup-check .btn:nth-child(2) {
  color: white;
  background-color: black;
}
.pop-list a {
  cursor: pointer;
}

#popupContainer {
  padding: 3% 0;
  box-sizing: border-box;
  justify-content: center;
  width: 100%;
}

#popupContainer.position-right {
  justify-content: right;
}

#popupContainer.position-left {
  justify-content: left;
}

#popupContainer .popup-contents {
  width: 100%;
  max-height: calc(100vh - 50px);
  overflow-y: scroll;
}

#popupContainer pre {
  width: 500px;
  max-width: 100%;
  word-break: break-all;
  text-align: left;
  white-space: pre-wrap;
  box-sizing: border-box;
  padding: 20px;
  font-family: "lifeplus", "Malgun Gothic","맑은 고딕",helvetica,"Apple SD Gothic Neo",sans-serif;
}

.popup-check .btn {
  cursor: pointer;
  background-color: white;
}

#popupContainer .popup {
  width: fit-content;
  max-width: 95%;
}

.swiper-slide {
  background-color: white;
  max-width: 100%;
}

.swiper-slide > a {
  display: inline-block;
  width: 100%;
}

@media only screen and (max-width:767px) {

  #popupContainer .popup-contents {
    max-height: calc(100vh - 60px);
  }
}

.swiper-button-next:after {
  margin-right: 5px;
}

.swiper-button-prev:after {
  margin-left: 5px;
}

.swiper-button-prev:after, .swiper-button-next:after {
  border-width: 3px !important;
}

.swiper-button-prev:hover:before, .swiper-button-next:hover:before {
    width: 0px !important;
}

.swiper-button-next:hover:before, .swiper-button-next:hover:after {
    margin-left: 0px !important;
    border-width: 3px;
}

.swiper-button-prev:hover:before, .swiper-button-prev:hover:after {
    margin-right: 0px !important;
    border-width: 3px;
}

.popup-wrap .pop-list .swiper-pagination .swiper-pagination-bullet {
    margin-right: 6px;
    margin-left: 6px;
    background: #d9d9d9;
    border-radius: 20px;
    opacity: 1;
}

.popup-wrap .pop-list .swiper-pagination .swiper-pagination-bullet-active {
    width: 22px;
    background: #000;
}
</style>